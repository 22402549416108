<template>
  <div>
    <el-popover
      placement="bottom"
      :width="400"
      trigger="click"
      v-model:visible="visibleVal"
    >
      <template #reference>
        <el-input v-model="inputVal" placeholder="请选择时间段">
          <template #prefix>
          <i class="el-input__icon el-icon-date"></i>
        </template>
        </el-input>
      </template>
      <el-row>
        <el-col :span="12">
          <div style="text-align: center;margin-bottom: 5px;">开始年份</div>
          <div style="display: flex;justify-content: space-around;">
            <i class="el-icon-d-arrow-left" @click="handlePageNext('dataLeft')"></i>
            <div>{{dataLeft[0].val}}年 - {{dataLeft[11].val}}年</div>
            <i class="el-icon-d-arrow-right" @click="handlePageto('dataLeft')"></i>
          </div>
          <div class="content-date">
            <el-button class="btn-date" :disabled="item.dis === 'false' ? false : true" :class="{active:item.val == dataLeftDate.val}" @click="handleTime('dataLeftDate', item)" size="mini" round v-for="(item, index) of dataLeft" :key="index">{{item.val}}</el-button>
          </div>
        </el-col>
        <el-col :span="12">
          <div style="text-align: center;margin-bottom: 5px;">结束年份</div>
          <div style="display: flex;justify-content: space-around;">
            <i class="el-icon-d-arrow-left" @click="handlePageNext('dataRight')"></i>
            <div>{{dataRight[0].val}}年 - {{dataRight[11].val}}年</div>
            <i class="el-icon-d-arrow-right" @click="handlePageto('dataRight')"></i>
          </div>
          <div class="content-date">
            <el-button class="btn-date" :disabled="item.dis === 'false' ? false : true" :class="{active:item.val == dataRightDate.val}" @click="handleTime('dataRightDate', item)" size="mini" round v-for="(item, index) of dataRight" :key="index">{{item.val}}</el-button>
          </div>
        </el-col>
      </el-row>
    </el-popover>
  </div>
</template>

<script>
export default {
  name: 'date-picker-year',
  data () {
    return {
      inputVal: '',
      dataLeft: [],
      dataRight: [],
      dataRightDate: '',
      dataLeftDate: '',
      visibleVal: false
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      const vm = this
      const dateYear = new Date()
      const nowyear = dateYear.getFullYear()
      for (let i = 0; i < 12; i++) {
        const obj = {
          dis: 'false',
          val: nowyear - i
        }
        vm.dataLeft.unshift(obj)
      }
      for (let i = 0; i < 12; i++) {
        const obj = {
          dis: 'false',
          val: nowyear - i
        }
        vm.dataRight.unshift(obj)
      }
    },
    handlePageNext (e) {
      const vm = this
      const nowyear = vm[e][0].val
      vm[e] = []
      for (let i = 1; i <= 12; i++) {
        let obj = {}
        if (e === 'dataLeft') {
          if (vm.dataRightDate.val < nowyear - i) {
            obj = {
              dis: 'true',
              val: nowyear - i
            }
          } else {
            obj = {
              dis: 'false',
              val: nowyear - i
            }
          }
        } else {
          if (vm.dataLeftDate.val > nowyear - i) {
            obj = {
              dis: 'true',
              val: nowyear - i
            }
          } else {
            obj = {
              dis: 'false',
              val: nowyear - i
            }
          }
        }
        vm[e].unshift(obj)
      }
    },
    handlePageto (e) {
      const vm = this
      const n = vm[e].length - 1
      const nowyear = vm[e][n].val
      vm[e] = []
      for (let i = 1; i <= 12; i++) {
        let obj = {}
        if (e === 'dataLeft') {
          if (vm.dataRightDate.val < nowyear + i) {
            obj = {
              dis: 'true',
              val: nowyear + i
            }
          } else {
            obj = {
              dis: 'false',
              val: nowyear + i
            }
          }
        } else {
          if (vm.dataLeftDate.val > nowyear + i) {
            obj = {
              dis: 'true',
              val: nowyear + i
            }
          } else {
            obj = {
              dis: 'false',
              val: nowyear + i
            }
          }
        }
        vm[e].push(obj)
      }
    },
    handleTime (e, item) {
      const vm = this
      vm[e] = item
    }
  },
  watch: {
    dataRightDate: {
      handler (newval, oldVal) {
        const vm = this
        const arr = []
        vm.dataLeft.forEach(item => {
          if (item.val > newval.val) {
            item.dis = 'true'
          } else {
            item.dis = 'false'
          }
          arr.push(item)
        })
        vm.dataLeft = []
        vm.dataLeft = arr
        const resultList = []
        resultList[0] = vm.dataLeftDate.val ? vm.dataLeftDate.val : ''
        resultList[1] = newval.val
        vm.inputVal = resultList[0] + '-' + resultList[1]
        if (vm.dataLeftDate && vm.dataRightDate) {
          vm.visibleVal = false
          vm.$emit('handleResule', resultList)
        }
      }
    },
    dataLeftDate: {
      handler (newval, oldVal) {
        const vm = this
        const arr = []
        vm.dataRight.forEach(item => {
          if (item.val < newval.val) {
            item.dis = 'true'
          } else {
            item.dis = 'false'
          }
          arr.push(item)
        })
        vm.dataRight = []
        vm.dataRight = arr
        const resultList = []
        resultList[1] = vm.dataRightDate.val ? vm.dataRightDate.val : ''
        resultList[0] = newval.val
        vm.inputVal = resultList[0] + '-' + resultList[1]
        if (vm.dataLeftDate && vm.dataRightDate) {
          vm.visibleVal = false
          vm.$emit('handleResule', resultList)
        }
      }
    }
    // dataLeft: {
    //   handler (newval, oldVal) {
    //     const vm = this
    //     const arr = []
    //     newval.forEach(item => {
    //       if (vm.dataRightDate.val < item.val) {
    //         item.dis = 'true'
    //       }
    //       arr.push(item)
    //     })
    //     vm.dataLeft = arr
    //   }
    // },
    // dataRight: {
    //   handler (newval, oldVal) {
    //     const vm = this
    //     const arr = []
    //     newval.forEach(item => {
    //       if (vm.dataLeftDate.val > item.val) {
    //         item.dis = true
    //       }
    //       arr.push(item)
    //     })
    //     vm.dataRight = arr
    //   }
    // }
  }
}
</script>

<style scoped="scoped">
.content-date {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
}
.el-button+.el-button, .el-checkbox.is-bordered+.el-checkbox.is-bordered {
  margin-left: 0;
}
.el-button {
  border: none;
}
.el-button.active {
  background-color: #0077AA;
  color: #fff;
}
</style>
